export const GA_MEASUREMENT_ID = 'G-BFGVSN55YC';

export const pageview = (url) => {
    console.log(url)
window.gtag("config", GA_MEASUREMENT_ID, {
page_path: url,
});
};

export const event = ({ action, category, label, value }) => {
window.gtag("event", action, {
event_category: category,
event_label: label,
value,
});
};