//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const ADD_QUANTITY_WITH_NUMBER = 'ADD_QUANTITY_WITH_NUMBER';
export const ORDER_FORM = 'ORDER_FORM';
export const CHECKOUT_CHARGE = 'CHECKOUT_CHARGE';
export const RESET_CART = 'RESET_CART';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const USRER_LOGIN = 'USRER_LOGIN';
export const CHECK_USRER_LOGIN = 'CHECK_USRER_LOGIN';
export const GET_FAVORITES = 'GET_FAVORITES';
export const USRER_LOGOUT = 'USRER_LOGOUT';
export const USRER_UPDATE = 'USRER_UPDATE';
export const GET_CART = 'GET_CART';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
export const MODAL_UPDATE = 'MODAL_UPDATE'
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const MANAGE_FAVORITES = 'MANAGE_FAVORITES'
export const ADD_ITEM_CART_MODAL = 'ADD_ITEM_CART_MODAL'
export const RESET_CART_MODAL = 'RESET_CART_MODAL'
export const SET_PAGE_OPENED = 'SET_PAGE_OPENED'
export const FETCHED_CART = 'FETCHED_CART'
export const SET_SITEWIDE_DEAL = 'SET_SITEWIDE_DEAL'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const SET_SITEWIDE_ADS = 'SET_SITEWIDE_ADS'