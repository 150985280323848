import '../styles.scss';
import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import { initStore } from '../store/reducers/cartReducer';
import Head from 'next/head'
import Script from 'next/script'
import dynamic from 'next/dynamic'         
const NewsletterModal = dynamic(() => import('../components/Modals/NewsletterModal'))
const AgeCheckerModal = dynamic(() => import('../components/Modals/AgeChecker'))
import NextNProgress from "nextjs-progressbar";
import * as gtag from "../lib/ga"
import cookie from 'js-cookie';
import '@szhsin/react-menu/dist/index.css';
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import 'rc-steps/assets/index.css';
import 'react-input-range/lib/css/index.css';
import "swiper/css";
import "swiper/css/navigation";
import 'react-dropdown/style.css';
import { useRouter } from 'next/router';
import { useInView } from "react-intersection-observer";

const MyApp = ({Component, pageProps, store}) => {
    const [ref, inView] = useInView({
		/* Optional options */
		triggerOnce: true,
		rootMargin: '0px 0px',
	})
    const [ ageChecker, setAgeChecker ] = useState(false)
    const [ newsletterModal, setNewsletterModal ] = useState(false)

    useEffect(() => {
        if(window!=window.top){} else {
            setTimeout(() => {
                if(!cookie.get('acceptedTerms')){
                setAgeChecker(true)
                }
            }, 5000);
            setTimeout(() => {
                if(!cookie.get('newsletterModal')){
                    setNewsletterModal(true)
                }
            }, 10000);
        } 
    });

    const router = useRouter()

    useEffect(() => {
        document.getElementById('globalLoader').style.display = 'none';
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
       
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
            
        };
    }, [router.events]);

    
    
    return (
        <> 
            <Head>
                <script rel="dns-prefetch" async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
                <noscript>
                    {/* <img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" referrerPolicy="no-referrer-when-downgrade" /> */}
                    </noscript>
                <Script
                    dangerouslySetInnerHTML={{
                      __html: `history.scrollRestoration = "manual"`,
                    }}
                />
                <script defer src="https://www.googletagmanager.com/gtag/js?id=G-BFGVSN55YC"></script>
                <script rel="dns-prefetch" defer
                    id='google-analytics'
                    dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-BFGVSN55YC', {
                        page_path: window.location.pathname,
                        });
                        `,
                    }}
                />
                <link rel="preconnect" href="https://res.cloudinary.com" />
                <link rel="dns-prefetch" href="https://res.cloudinary.com" />
                
            </Head>
            
         
            
            <Provider store={store}>
                <>
                    {
                        ageChecker && <AgeCheckerModal  
                            onClick={()=>{setAgeChecker(false)}} 
                            active={ ageChecker ? 'active' : ''} 
                        />
                    }

                    {
                        newsletterModal && <NewsletterModal  
                            onClick={()=>{setNewsletterModal(false)}} 
                            active={ newsletterModal ? 'active' : ''} 
                        />
                    }

                    <NextNProgress color={'#6c3478'}  options={{ showSpinner: false }} />
                    <div ref={ref}></div>
                    <Component 
                        {...pageProps} 
                    />
                </>
            </Provider> 
        </>
    );
}
export default withRedux(initStore)(MyApp)



